import { Component, ElementRef, Renderer2, ViewChild } from "@angular/core";
import { HeaderService } from "src/app/core/services/header.service";
import { StorageService } from "src/app/core/services/storage.service";

@Component({
  selector: 'BTA-btm-section',
  templateUrl: './btm-section.component.html',
  styleUrls: ['./btm-section.component.scss']
})

export class BtmSectionComponent {

}