import { Component, OnInit } from '@angular/core';
import { HeaderService } from './core/services/header.service';
import { DataShareService } from './core/services/datashare.sevice';
import { VisaService } from './core/services/visa.service';
import { HolidayService } from './core/services/holiday.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private headerService: HeaderService,
    private dataShareService: DataShareService,
    private holidayService:HolidayService
  ) {}
  ngOnInit() {
    //  document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    this.getAllCountryCodes();
    this.getLookUpCountries()
    
  }

  getAllCountryCodes() {
    this.headerService.getAllCountries().subscribe({
      next: (response: any) => {
        this.dataShareService.setCountryCodes(response.length?response:[]);
      },
    });
  }

  getLookUpCountries(){
    this.holidayService.getAllCountry().subscribe({
      next: (res: any) => {
        this.dataShareService.setAllCountry(res);
      },
    });
  }
 
}
