<div class="container-fluid">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 order-lg-0 order-1">
            <div class="p-lg-5 p-3">
                <p class="blue-text fw-medium mb-1">PRODUCTS</p>
                <div class="h2 h2-size fw-bolder red-text">Booking Engine</div>
                <p class="lead">A travel booking engine is a software program that acts like an online travel
                    agent.</p>
                <p>A travel booking engine is a software program that acts like an online travel agent. It
                    allows users to search for and book various travel products like flights, hotels, rental
                    cars, and even activities, all through a single platform.</p>
                <button class="btn btn-lg blue-btn text-white">Explore</button>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 booking-engine order-lg-1 order-0">

        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 distribution-solution"></div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="p-5p-lg-5 p-3">
                <p class="blue-text fw-medium mb-1">PRODUCTS</p>
                <div class="h2 h2-size fw-bolder red-text">Distribution Solution</div>
                <p class="lead">Travel technology distribution solutions are the software platforms that act as
                    the middlemen between travel suppliers and travel sellers .</p>
                <p>In essence, travel technology distribution solutions streamline the travel booking process
                    for both travel sellers and travelers. Travel sellers benefit from a wider selection of
                    inventory, efficient booking tools, and real-time data. Travelers gain access to a wider
                    range of options, competitive prices, and a convenient booking experience.</p>
                <button class="btn btn-lg blue-btn text-white">Explore</button>
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 order-lg-0 order-1">
            <div class="p-lg-5 p-3">
                <p class="blue-text fw-medium mb-1">PRODUCTS</p>
                <div class="h2 h2-size fw-bolder red-text">TMC Whitelabel</div>
                <p class="lead">A white-label solution for travel agencies is essentially a pre-built online
                    booking platform that you can brand as your own. </p>
                <p>The white-label platform provides the core functionalities like flight, hotel, car rental,
                    and potentially activity searches and booking capabilities. It essentially acts as a travel
                    booking engine with a blank brand identity.</p>
                <button class="btn btn-lg blue-btn text-white order-lg-1 order-0">Explore</button>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 white-label">

        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 unifiedAPI"></div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="p-lg-5 p-3">
                <p class="blue-text fw-medium mb-1">PRODUCTS</p>
                <div class="h2 h2-size fw-bolder red-text">Unified API</div>
                <p class="lead">Travel APIs are the messengers that ferry travel information between different
                    parts of the travel industry.</p>
                <p>Travel APIs act as intermediaries between travel sellers (online booking engines, travel
                    agencies) and travel suppliers (airlines, hotels, car rentals).</p>
                <button class="btn btn-lg blue-btn text-white">Explore</button>
            </div>
        </div>

    </div>
</div>