<div class="b-overlay pt-5 pb-5 text-center text-white">
    <div class="container">
        <p>B2B Travel Agency</p>
        <div class="h1 fw-bolder mb-4 text-white">Top technology platforms that connect<br> travel suppliers with travel
            sellers
        </div>
        <p>The internet has transformed the businesses and the travel business is no exception. With the arrival
            of technology the mode of business has gone for a sea change. Deals are being sealed online over
            mobiles, tablets and computers, and OTAs are looking for a business-to-business travel agency from
            where they can get the best deals to meet the demand of their informed clients.</p>
        <button class="btn btn-lg blue-btn text-white mt-4">Contact Us</button>
    </div>
</div>