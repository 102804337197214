import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataShareService {
  constructor(private http: HttpClient) {}
  private searchSubject=new BehaviorSubject<any>('')
   searchSubject$=this.searchSubject.asObservable();
  private adminPanelType = new BehaviorSubject<string>('');
  public adminPanel = this.adminPanelType.asObservable();
  private countrySubject = new BehaviorSubject<any[]>([]);
  countrySubject$ = this.countrySubject.asObservable();
  private priceCountrySubject = new BehaviorSubject<any[]>([]);
  priceCountrySubject$ = this.priceCountrySubject.asObservable();
  private tableDataSubject = new BehaviorSubject<any[]>([]);
  tableDataSubject$ = this.tableDataSubject.asObservable();
  private categorySubject = new BehaviorSubject<any[]>([]);
  categorySubject$ = this.categorySubject.asObservable();
  private typeSubject = new BehaviorSubject<any[]>([]);
  typeSubject$ = this.typeSubject.asObservable();
  private counrtyCodeSubject=new BehaviorSubject<any[]>([])
  counrtyCodeSubject$=this.counrtyCodeSubject.asObservable()
  getadminPanelType(): Observable<string> {
    return this.adminPanelType.asObservable();
  }

  setAdminPanelType(adminType: string): void {
    this.adminPanelType.next(adminType);
  }

  setAllCountry(value: any[]) {
    this.countrySubject.next(value);
  }

  setPriceCountries(value: any[]) {
    this.priceCountrySubject.next(value);
  }

  setTableData(value: any[]) {
    this.tableDataSubject.next(value);
  }

  setCategoryData(value: any[]) {
    this.categorySubject.next(value);
  }

  setTypeData(value: any[]) {
    this.typeSubject.next(value);
  }

  searchList(value:any){
    this.searchSubject.next(value)
  }

  setCountryCodes(value:any[]){
    this.counrtyCodeSubject.next(value)
  }
}
