<div class="container">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <p class="blue-text fw-bolder">Foremost Travel Aggregator in India</p>
            <div class="h1 fw-bold">Empower Your <br><span class="red-text">Business Travel</span> <br>with
                Seamless <br>Solutions</div>
            <p>Discover a new standard in corporate travel management. Our platform offers streamlined booking,
                exclusive corporate rates, and 24/7 support, all tailored to meet the unique needs of your
                business. Simplify travel planning and maximize efficiency with our comprehensive travel
                solutions.</p>
            <button class="btn btn-lg blue-btn text-white">Contact Us</button>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 ">
            <div class="position-relative h-100">
                <div class="bnr-flight"></div>
                <div class="bnr-fsearch"></div>
                <div class="bnr-holiday"></div>
                <div class="bnr-hotel"></div>
            </div>
        </div>
    </div>
</div>