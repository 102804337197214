import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class FlightService {

    constructor(private http: HttpClient) { }

    searchAirPortList(search_key) {
        return this.http.get(`${environment.apiUrl}search_airport?search_key=${search_key}`)
    }

    // flight serach api for getting the session id

    getSessionIdFromFlightSearch(flightSearchForm) {
        return this.http.post(`${environment.flightApi}flights/flight-search`, flightSearchForm);
    }

    // get flight list using session id
    getFlightSerachResults(sessionIdForm) {
        // let jsonUrl = 'assets/json/international_roundtrip.json';
        // return this.http.get<any>(jsonUrl);

        return this.http.post(`${environment.flightApi}flights/flight-search-data`, sessionIdForm);
    }
    getAirportList(){
        return this.http.get(`${environment.apiUrl}lookup_airport`)
    }
    getDefaultValues(countryId){
        return this.http.get(`${environment.apiUrl}default_values?country_id=${countryId}`)
    }
    getAccountBalance(){
        return this.http.get(`${environment.apiUrl}accounting/shared/credit-balance/`)
        //return this.http.get(`${environment.apiUrl}`)
    }
}