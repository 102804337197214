import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { excludeRoutesArray } from 'src/app/core/constants/exclude-routes';

@Component({
  selector: 'app-horizontal',
  templateUrl: './horizontal.component.html',
  styleUrls: ['./horizontal.component.scss'],
})

/**
 * Horizontal-layout component
 */
export class HorizontalComponent implements OnInit, AfterViewInit {
  isCondensed = false;

  constructor(private router: Router) {}

  ngOnInit() {
    document.body.setAttribute('data-layout', 'horizontal');
   
  }

  ngAfterViewInit() {}

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }

  /**
   * Mobile Toggle Menu
   */
  onToggleMobileMenu() {
    const element = document.getElementById('topnav-menu-content');
    element?.classList.toggle('show');
  }

  excludeRoutes():boolean {
    let currentRoute=this.router.url
    if (excludeRoutesArray.includes(currentRoute)) {
      return true
    }

    return false
  }
}
