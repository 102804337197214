import { Component, ElementRef, Renderer2, ViewChild } from "@angular/core";
import { HeaderService } from "src/app/core/services/header.service";
import { StorageService } from "src/app/core/services/storage.service";

@Component({
  selector: 'BTA-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})

export class ProductsComponent {

}