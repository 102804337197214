import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layouts/layout.component';
import { PageNotFoundComponent } from './shared/404/page-not-found/page-not-found.component';
const routes: Routes = [
  { path: '', redirectTo: 'home-page' ,pathMatch:'full'},
  { path: 'home-page', loadChildren: () => import('./home-page/home-page.module').then(m=>m.HomePageModule)},
  { path: '', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule)},
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
