import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class DataShareService {

  private searchSubject = new BehaviorSubject<any>('')
  searchSubject$ = this.searchSubject.asObservable();
  private adminPanelType = new BehaviorSubject<string>('');
  public adminPanel = this.adminPanelType.asObservable();
  private countrySubject = new BehaviorSubject<any[]>([]);
  countrySubject$ = this.countrySubject.asObservable();
 
  private tableDataSubject = new BehaviorSubject<any[]>([]);
  
  tableDataSubject$ = this.tableDataSubject.asObservable();
  private categorySubject = new BehaviorSubject<any[]>([]);
  categorySubject$ = this.categorySubject.asObservable();
  private typeSubject = new BehaviorSubject<any[]>([]);
  typeSubject$ = this.typeSubject.asObservable();
  private counrtyCodeSubject = new BehaviorSubject<any[]>([])
  counrtyCodeSubject$ = this.counrtyCodeSubject.asObservable()
  status=[
    {value: 'active', viewValue: 'Active'},
    {value: 'inactive', viewValue: 'Inactive'},
    {value: 'pending', viewValue: 'Pending'},
  ]

  private creaditDetails = new BehaviorSubject<any[]>([])
  creaditDetails$ = this.creaditDetails.asObservable()

  // flight booking id==============================================
  private flightBookingIdSubject = new BehaviorSubject<any | null>(this.getflightBookingIdFromLocalStorage());
  flightBookingId$ = this.flightBookingIdSubject.asObservable();

  //flight passenger details emission=====================================================
  private passengerDetailsSubject = new BehaviorSubject<any | null>(this.getPassengerDetails())
  passengerDetailsSubject$ = this.passengerDetailsSubject.asObservable()

  constructor(private http: HttpClient, private storageService: StorageService) { }

  getadminPanelType(): Observable<string> {
    return this.adminPanelType.asObservable();
  }

  setAdminPanelType(adminType: string): void {
    this.adminPanelType.next(adminType);
  }

  setAllCountry(value: any[]) {
    this.countrySubject.next(value);
  }

  

  setTableData(value: any[]) {
    this.tableDataSubject.next(value);
  }

  setCategoryData(value: any[]) {
    this.categorySubject.next(value);
  }

  setTypeData(value: any[]) {
    this.typeSubject.next(value);
  }

  searchList(value: any) {
    this.searchSubject.next(value)
  }

  

  setCountryCodes(value: any[]) {
    this.counrtyCodeSubject.next(value)
  }

  setCreaditValue(value: any){
    this.creaditDetails.next(value)
  }

  private getflightBookingIdFromLocalStorage(): Observable<any | null> {
    const flightBookingId = this.storageService.getLocalStorageItem('flightBookingId');
    return flightBookingId ? flightBookingId : "";
  }

  // Method to set ID
  setflightBookingId(flightBookingId: any): void {
    if (flightBookingId) {
      this.storageService.setLocalStorageItem('flightBookingId', flightBookingId);
      this.flightBookingIdSubject.next(flightBookingId);
    }
  }

  getFlightBookingIdObservable(): Observable<any | null> {
    return this.flightBookingIdSubject.asObservable();
  }

  private getPassengerDetails(): Observable<any | null> {
    const PassengerDetails = this.storageService.getLocalStorageItem('passengerDetails');
    return PassengerDetails ? PassengerDetails : "";
  }

  //flight passenger Data emission===========
  setPassengerDetails(value: any[]) {
    if (value) {
      this.storageService.setLocalStorageItem('passengerDetails', value);
      this.passengerDetailsSubject.next(value);
    }
  }
}
