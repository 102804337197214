import { Component, OnInit } from '@angular/core';
import { HeaderService } from './core/services/header.service';
import { DataShareService } from './core/services/datashare.sevice';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private headerService: HeaderService,
    private dataShareService: DataShareService
  ) {}
  ngOnInit() {
    //  document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    this.getAllCountryCodes();
  }

  getAllCountryCodes() {
    this.headerService.getAllCountries().subscribe({
      next: (response: any) => {
        this.dataShareService.setCountryCodes(response.length?response:[]);
      },
    });
  }
}
