import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    constructor(private http: HttpClient){}

    getInit(){
        return this.http.get(`${environment.apiUrl}api/user-ip/`)
    }

    getVerifyGstin(gst_number){
        return this.http.get(`${environment.apiUrl}verify_gstin/?gst_number=${gst_number}`)
    }

}
//api/user-ip/