import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { HomeHeaderComponent } from './home-header/home-header.component';
import { HomePageComponent } from './home-page.component';
import { BannerSectionComponent } from './banner-section/banner-section.component';
import { ComprehensiveSectionComponent } from './comprehensive-section/comprehensive-section.component';
import { MidSectionComponent } from './mid-section/mid-section.component';
import { ProductsComponent } from './products/products.component'
import { BtmSectionComponent } from './btm-section/btm-section.component';
import { FooterComponent } from './footer/footer.component';
import { SharedMaterialModule } from '../shared-material.module';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
    {
        path: '',
        component: HomePageComponent
    },
    { path: 'account', loadChildren: () => import('../account/account.module').then(m => m.AccountModule)},
   
];

@NgModule({
    declarations: [
        HomeHeaderComponent,
        HomePageComponent,
        BannerSectionComponent,
        ComprehensiveSectionComponent,
        MidSectionComponent,
        ProductsComponent,
        BtmSectionComponent,
        FooterComponent,
        LoginComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedMaterialModule
    ],
    providers: [],
    exports: [
        HomeHeaderComponent, 
        HomePageComponent, 
        BannerSectionComponent, 
        ProductsComponent, 
        BtmSectionComponent, 
        FooterComponent,
        LoginComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
      ]
})

export class HomePageModule { }

