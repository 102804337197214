import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class ToastService {
  verticalPosition: 'top' | 'bottom' = 'top';
  horizontalPosition: 'start' | 'center' | 'end' | 'left' | 'right' = 'right';
  duration: number = 3000; // Duration in milliseconds
  setAutoHide: boolean = true;
  addExtraClass: boolean = true;
  message: string = 'This is a message with a progress bar!';
  action: boolean = false;
  actionButtonLabel: string = 'Close';
  constructor(private _snackBar: MatSnackBar) {}

  success(message: string, action: string) {
    const config = {
      verticalPosition: this.verticalPosition,
      horizontalPosition: this.horizontalPosition,
      duration: this.setAutoHide ? this.duration : 0,
      panelClass: this.addExtraClass ? ['custom-snackbar-success'] : undefined
    };
    const snackBarRef = this._snackBar.open(message, action ? this.actionButtonLabel : undefined, config);

    // Adding animation class manually
    snackBarRef.afterOpened().subscribe(() => {
      const container = document.querySelector('mat-snack-bar-container.custom-snackbar-success');
      if (container) {
        container.classList.add('animate-progress-bar');
      }
    });
  }
  error(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: ['custom-snackbar-error'],
    });
  }
  warning(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass:['custom-snackbar-warning'],
    });
  }

}
