import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { KEY_BOARD_KEY } from 'src/app/core/enum/keys.enum';
import {
  authResponse, loginForm, loginResponse,
} from 'src/app/core/models/auth.models';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { Observable } from 'rxjs';
import { DataShareService } from 'src/app/core/services/datashare.sevice';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

/**
 * Login component
 */
export class LoginComponent implements OnInit {
  item: any[] = [false, false, false];
  countries;
  tabIndex: number = 0;
  loginForm: FormGroup;
  selected_countryid;
  submitted = false;
  loginRespone: loginResponse;
  reTypePassword: string = '';
  filteredOptions: Observable<[]>;
  rememberMe: boolean = false;
  isLoading: boolean = false;
  otpResponse: authResponse;
  showPassword: boolean[] = [];
  year: number = new Date().getFullYear();
  @ViewChild('input') input!: ElementRef<HTMLInputElement>;

  isPasswordChange: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private authService: AuthenticationService,
    private router: Router,
    private tostrService: ToastService,
    private sharedService:DataShareService
  ) { }

  ngOnInit() {
    this.moveFlight();
    this.setAttribute();
    this.formInit();
    this.initializeFormArray();
    this.setItem(0);
    this.initialValues();
  }

  moveFlight() {
    //moveFlight();
  }

  formInit() {
    const userData = this.storageService.getLocalStorageItem('userDetails');
    this.rememberMe = userData?.isRememberMe;
    this.loginForm = this.formBuilder.nonNullable.group<loginForm>({
      email: new FormControl(this.rememberMe ? userData?.email : '', [Validators.required, Validators.email]),
      phone_code: new FormControl(this.rememberMe ? userData?.email : ''),
      phone: new FormControl(this.rememberMe ? userData?.phone : ''),
      password: new FormControl(this.rememberMe ? userData?.password : '', [
        Validators.required,
       // Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%,;*?&#_=<\/[\]])[A-Za-z\d@$!%,;*?&#_=<\/[\]]{8,}$/), Validators.minLength(8)
      ]),
      otp: this.formBuilder.array([]),
      newPassword: new FormControl('', [Validators.required]),
      reTypePassword: new FormControl('', [Validators.required]),
    });
  }

  initializeFormArray() {
    const otpArray = this.loginForm.get('otp') as FormArray;
    for (let i = 0; i < 6; i++) {
      otpArray.push(this.formBuilder.control(''));
    }
  }
  initialValues() {
    this.selected_countryid = this.storageService.getLocalStorageItem('selected_country');
    this.sharedService.counrtyCodeSubject$.subscribe((response: []) => {
      this.countries = response;
    });
  }

  onPaste(event: any) {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('text').replace(/\D/g, '');
    const otpArray = this.loginForm.get('otp') as FormArray;

    for (let i = 0; i < 6; i++) {
      otpArray.at(i).setValue(pasteData[i] || '');
    }
  }

  onInput(event: KeyboardEvent, index: number) {
    const input = event.target as HTMLInputElement;
    const keyArr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const key = event.key;
    if (input.value.length > 1) {
      input.value = input.value.slice(-1);
    }

    if (key === KEY_BOARD_KEY.BACKSPACE && index > 0) {
      const nextIndex = index - 1;
      const nextInput = document.getElementById(
        'otp' + nextIndex
      ) as HTMLInputElement;
      if (nextInput) {
        nextInput.focus();
      }
    }
    if (keyArr.includes(key) && index < this.otpArray.length - 1) {
      const nextIndex = index + 1;
      const nextInput = document.getElementById(
        'otp' + nextIndex
      ) as HTMLInputElement;
      if (nextInput) {
        nextInput.focus();
      }
    }

    if (key === KEY_BOARD_KEY.ARROW_LEFT) {
      const nextIndex = index - 1;
      const nextInput = document.getElementById(
        'otp' + nextIndex
      ) as HTMLInputElement;
      if (nextInput) {
        nextInput.focus();
      }
    }
    if (key === KEY_BOARD_KEY.ARROW_RIGHT) {
      const nextIndex = index + 1;
      const nextInput = document.getElementById(
        'otp' + nextIndex
      ) as HTMLInputElement;
      if (nextInput) {
        nextInput.focus();
      }
    }
  }
  setAttribute() {
    document.body.setAttribute('class', 'authentication-bg');
    // document.body.style.overflow = 'hidden';
    document.body.removeAttribute('data-topbar');
  }

  ngOnDestroy() {
    document.body.classList.remove('authentication-bg');
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  get otpArray(): FormArray {
    return this.loginForm.get('otp') as FormArray;
  }

  changePassword() {
    if (!this.f['newPassword'].value || !this.f['reTypePassword'].value) {
      this.loginForm.get('newPassword').markAsTouched();
      this.loginForm.get('reTypePassword').markAsTouched();
      return;
    }

    this.isLoading = true;
    const data = {
      new_password: this.f['newPassword'].value,
      confirm_password: this.f['reTypePassword'].value,
    };
    this.authService.updatePassword(data).subscribe({
      next: (res) => {
        this.tostrService.success('Password updated successfully', 'OK');
        this.isLoading = false;
        this.router.navigate(['/flight']);
      },
      error: (err) => {
        this.tostrService.success(err.error.message, 'Cancel');
        this.isLoading = false;
      },
    });
  }

  togglePassword(type: boolean, index: number) {
    this.showPassword[index] = type;
  }

  selectedTabChange(event: any) {

    if (event.index == 0) {
      this.loginForm.get('email').setValidators([Validators.required]);
      this.loginForm.get('phone').clearValidators();
      this.loginForm.get('phone_code').clearValidators();
    } else if (event.index == 1) {
      this.loginForm.get('phone_code').setValidators([Validators.required]);
      this.loginForm.get('phone').setValidators([Validators.required]);
      this.loginForm.get('email').clearValidators();
    }
    this.tabIndex = event.index;
    this.loginForm.get('phone_code').updateValueAndValidity();
    this.loginForm.get('phone').updateValueAndValidity();
    this.loginForm.get('email').updateValueAndValidity();

  }

  verifyOtp() {
    let otp = '';
    for (let control of this.otpArray.controls) {
      otp += control.value;
    }
    if (otp.length != this.otpArray.length) {
      this.tostrService.error('Please fill otp', 'Cancel');
      return;
    }

    this.isLoading = true;
    const otpData = {
      email_or_phone: this.f['email'].value,
      otp: otp,
    };
    this.authService.verifyOtp(otpData).subscribe({
      next: (res) => {
        this.otpResponse = res;
        const rememberMe = {
          isRememberMe: this.rememberMe,
          email: this.f['email'].value,
          phone: this.f['phone'].value,
          phone_code: this.f['phone_code'].value
        };
        if (res?.access_token && res?.refresh_token) {
          const token = {
            access_token: res.access_token,
            refresh_token: res.refresh_token,
          };
          this.storageService.setLocalStorageItem('token', token);
        }
        this.storageService.setLocalStorageItem('permissions', res?.permissions);
        this.storageService.setLocalStorageItem('user_name', res?.User_Name);
        this.storageService.setLocalStorageItem('button_color', 'red');
        this.isLoading = false;
        this.storageService.setLocalStorageItem('userDetails', rememberMe);

        this.authService.setValue();
        if (this.loginRespone?.is_first_time) {
          this.setItem(2);
        } else {
          this.router.navigate(['/flight']);
        }

        this.tostrService.success('Successfully loggedin.', 'OK');
      },
      error: (err) => {
        this.isLoading = false;
        this.setItem[1];
        this.tostrService.error(err.error.message, 'Cancel');
      },
    });
  }

  setItem(index: number) {
    for (let i = 0; i < this.item.length; i++) {
      if (index === i) {
        this.item[index] = true;
      } else {
        this.item[i] = false;
      }
    }
  }

  onSubmit() {
    
    if ((!this.f['email'].value && this.tabIndex == 0) || !this.f['password'].value || (!this.f['phone_code'].value || !this.f['phone'].value) && this.tabIndex == 1 || this.loginForm.get('password').invalid) {
      this.loginForm.get('email').markAsTouched();
      this.loginForm.get('password').markAsTouched();
      this.loginForm.get('phone_code').markAsTouched();
      this.loginForm.get('phone').markAsTouched();
      return;
    }

    this.isLoading = true;
    const selected_country = this.storageService.getLocalStorageItem('selected_country');
    const authCredentials = {
      email_or_phone: this.tabIndex == 0 ? this.f['email'].value : this.f['phone'].value,
      password: this.f['password'].value,
      country_name: this.selected_countryid.name,
      country_code: this.tabIndex == 1 ? this.f['phone_code'].value : selected_country?.calling_code
    };
    this.authService.login(authCredentials).subscribe({
      next: (res) => {
        if (res) {
          this.loginRespone = res;
          this.setItem(1);
          this.isLoading = false;
        }
      },
      error: (err) => {
        this.tostrService.error(err.error.message, 'Cancel');
        this.setItem(0);
        this.isLoading = false;
      },
    });
  }
  filter(): void {
    const filterValue = this.input.nativeElement.value.toLowerCase();
    this.filteredOptions = this.countries?.filter((country: any) => {
      const filterValueLower = filterValue?.toLowerCase(); // Convert filterValue to lowercase
      const nameLower = country.country_name?.toLowerCase(); // Convert country name to lowercase for case-insensitive comparison
      const codeLower = country.country_code?.toLowerCase(); // Convert country code to lowercase for case-insensitive comparison
      return (
        nameLower.includes(filterValueLower) ||
        country.country_code.includes(filterValue) || // Assuming dial_code is a string
        codeLower.includes(filterValueLower)
      );
    });
  }
}
