<BTA-home-header (loginFlag)="loginFlag($event)"></BTA-home-header>

<section class="banner-section pt-4 pb-5">
    <BTA-banner-section></BTA-banner-section>
</section>

<section class="pt-lg-5 pt-3 pb-lg-3 pb-2 border-top">
    <BTA-comprehensive-section></BTA-comprehensive-section>
</section>

<section class="mid-section text-white">
    <BTA-mid-section></BTA-mid-section>
</section>

<section>
    <BTA-products></BTA-products>
</section>

<section class="btm-section">
    <BTA-btm-section></BTA-btm-section>
</section>

<section class="pt-3 pb-3" #sectionElement>
    <BTA-footer></BTA-footer>
</section>


<div *ngIf="loginStatus" [ngClass]="{'login-window' : loginStatus, 'shadow' : loginStatus}">
    <div *ngIf="loginStatus" class="text-end"><mat-icon class="login-close" (click)="loginStatus = false">close</mat-icon></div>
    <app-login></app-login>
</div>

<div [ngClass]="{'login-overlay' : loginStatus}" class=""></div>