<div class="container">
    <div class="h1 fw-bolder text-center mb-3">Comprehensive <span class="red-text">Travel<br> Services</span>
        Consolidated in a <br>Single Hub</div>
    <p class="text-center">Efficiently Find the Most Affordable Hotels and Flight Bookings, Explore Exciting
        Vacation Tours, Access Business Travel Packages, Visa Services, Insurance Options, Currency Exchange,
        and Private Charters - All in One Convenient Platform.</p>
    <div class="row mt-4">
        <div class="col-lg-3 col-6"><img src="../../../../assets/images/home/tj.png" class="img-fluid"></div>
        <div class="col-lg-3 col-6"><img src="../../../../assets/images/home/Amadeus.png" class="img-fluid"></div>
        <div class="col-lg-3 col-6"><img src="../../../../assets/images/home/tbo.png" class="img-fluid"></div>
        <div class="col-lg-3 col-6"><img src="../../../../assets/images/home/mmt.png" class="img-fluid"></div>
    </div>
</div>