<section class="header pt-lg-3 pt-3">
  <div class="container">
    <div class="row header1">
      <div class="col-lg-2 col-2"><img src="https://book-staging-bucket.s3.ap-south-1.amazonaws.com/assets/images/flight/b2b-logo.png" height="60"></div>
      <div class="col-lg-10 col-10">
        <div class="top-right-nav pt-lg-4 pt-4 text-end d-flex float-right font-weight-400">
          <span class="d-lg-inline-block d-none"><a href="#">Support </a> <span class="devider">|</span> <strong matTooltip="{{totalAmount?.F}}/{{totalAmount?.FC}}">Cash
              Bal: {{totalAmount?.F ? totalAmount?.F : '∞'}}</strong> <span class="devider">|</span> <a href="#">Recharge</a> <span
              class="devider">|</span> </span>
          <span class="prof-link d-block" (click)="profileIcon =! profileIcon">Welcome : {{this.userName}}
            <div class="prof-pop shadow" [ngClass]="{'d-block' : profileIcon}">
              <ul>
                <li><a>Agency Account</a>
                <li><a>Settings</a></li>
                <li><a (click)="logout()">Logout</a></li>
              </ul>
            </div>
          </span>

        </div>

      </div>
    </div>
  </div>
  <div class="inner-nav">
    <div class="container">
      <div class="mob-nav-head">
        <div class="row">
          <div class="col-2">
            <div class="nav-btn" (click)="mobileIconView =! mobileIconView"><img
                src="https://book-staging-bucket.s3.ap-south-1.amazonaws.com/assets/images/icons/hamburger-menu.svg" height="30"></div>
          </div>
          <div class="col-10">
            <div class="m-head-nav"><a href="#">Support </a> <span class="devider">|</span> <strong>Cash Bal:
                Rs.0.00</strong> <span class="devider">|</span> <a href="#">Recharge</a> </div>
          </div>
        </div>
      </div>
      <div class="mobile-menu shadow-sm" [ngClass]="{'d-block' : mobileIconView}">
        <div class="m-close-btn" (click)="mobileIconView =! mobileIconView"><img
            src="https://book-staging-bucket.s3.ap-south-1.amazonaws.com/assets/images/flight/close.svg" height="20"></div>
        <ul>
         
          @for (item of menuItems; track $index) {
          <li class="submenu-link li-list" [ngClass]="{'submenu-link' : hasItems(item)}"><a class="pop-up active-link "
              (click)="onMenuClick($event, $index, item)" [ngClass]="{'active' : activeButton == item.label}"
              [routerLink]="item.link" role="button">{{ item.label
              }}</a>
            @if(hasItems(item)){
            <div class="dropdown-menu dropdown" #dropdown
              [ngClass]="{'dropdown-mega-menu-xl px-2':  item.subItems.length > 11, 'show' : $index === index}" aria-labelledby="topnav-dashboard">
              @for(subitem of item.subItems;track $index){
              @if(item.subItems.length < 11 && !hasItems(subitem)) { <a class="col dropdown-item side-nav-link-ref"
                [routerLink]="subitem.link" href="javascript: void(0);" routerLinkActive="active">{{subitem.label }}
                </a>}
                @if(item.subItems.length > 11){
                <div>
                  @if($index % 3 == 0){
                  <div class="row">
                    <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active"
                        [routerLink]="subitem.link">{{item.subItems[$index].label }}</a></div>
                    <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active"
                        [routerLink]="item.subItems[$index + 1]?.link">{{item.subItems[$index + 1]?.label }}</a>
                    </div>
                    <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active"
                        [routerLink]="item.subItems[$index + 2]?.link">{{item.subItems[$index + 2]?.label }}</a>
                    </div>
                  </div>}
                </div>
                }
                @if(hasItems(subitem)){
                <div class="dropdown">
                  <a class="dropdown-item" href="javascript: void(0);" (click)="onMenuClick($event)">{{
                    subitem.label }}
                    <div class="arrow-down"></div>
                  </a>
                  <div class="dropdown-menu">
                    <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                      @if(!hasItems(subSubitem) && subSubitem?.subItems == undefined ){
                      <!-- <ng-container *ngIf="!hasItems(subSubitem)"> -->
                      <a class="dropdown-item side-nav-link-ref" [routerLink]="subSubitem.link"
                        routerLinkActive="active">{{ subSubitem.label }}</a>
                      <!-- </ng-container> -->
                      }
                      <ng-container *ngIf="hasItems(subSubitem) && subSubitem.subItems">
                        <div class="dropdown d-flex">
                          <a class="dropdown-item side-nav-link-ref" [routerLink]="subSubitem.link"
                            routerLinkActive="active" (click)="onSubMenuClick($event)">{{ subSubitem.label }}</a>
                          <div class="arrow-down"></div>
                        </div>
                        <div class="dropdown-menu side side-nav-link-ref" #subMenu>
                          <ng-template ngFor let-subSubitems [ngForOf]="subSubitem.subItems">
                            <a class="dropdown-item side-nav-link-ref" [routerLink]="subSubitems.link"
                              routerLinkActive="active">{{ subSubitems.label }}</a>
                          </ng-template>
                        </div>
                      </ng-container>
                    </ng-template>
                  </div>
                </div>}
                }
            </div>
            }
          </li>
          }

        </ul>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</section>